import React from 'react'
import { IconButton } from '@chakra-ui/react'

export const SliderArrow = ({ size, direction, theme, ...rest }) => {
	const _styleLeft = { left: size == 'lg' ? '-56px' : '-48px' } //-32px + -16px
	const _styleRight = { right: size == 'lg' ? '-56px' : '-48px' } //-32px
	const _styleLarge = {
		borderRadius: 'var(--vidday-radii-xl)',
		height: 'calc(100% - .5rem)',
		marginTop: '-.25rem',
		maxWidth: '3rem',
		width: '3rem',
	}

	const styleDirection = direction == 'left' ? _styleLeft : _styleRight
	const styleSize = size == 'lg' ? _styleLarge : {}

	return (
		<IconButton
			// cursor="pointer"
			position="absolute"
			top="50%"
			zIndex="500"
			// boxShadow="1px 2px 3px rgb(0 0 0 / 15%)"
			transform="translateY(-50%)"
			variant="pageArrow"
			theme={theme}
			display={['none', 'block']}
			{...styleSize}
			{...styleDirection}
			{...rest}
		/>
	)
}
