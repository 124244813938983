import React from 'react'
import { Stack, Image, Text, Flex, Button, useColorMode, useColorModeValue, Box } from '@chakra-ui/react'
import useImageGenerator from '../../../hooks/useImageGenerator'
import useWindowSize from '../../../hooks/useWindowSize'

export const FeatureBase = ({ isFullyClickable, item, ...rest }) => {
	const { href, isExternal, onClick } = item

	/** Define clickable props for the button link */
	const linkProps = isExternal ? { as: 'a', href: href, target: '_blank' } : { onClick }

	/** Define clickable props for the whole card if fully clickable */
	const fullCardClickProps = isFullyClickable ? { ...linkProps } : {}

	const borderColor = useColorModeValue('gray.200', 'whiteAlpha.300')
	return (
		<Stack
			{...fullCardClickProps}
			cursor={isFullyClickable ? 'pointer' : 'default'}
			minH="80px"
			borderRadius="var(--vidday-radii-xl)"
			border="1px"
			borderColor={borderColor}
			direction={['column', 'row']}
			justify={['start', 'space-between']}
			alignItems={['start', 'center']}
			spacing="1rem"
			p="1rem"
			{...rest}
		/>
	)
}

const FeatureItemBase = ({ isFullyClickable, item }) => {
	const { colorMode } = useColorMode()
	const { width } = useWindowSize()

	const { path, responsive, btnProps, content, buttonText, onClick, isExternal, href, imgTransform } = item

	let imgPath = path
	if (responsive) {
		if (width <= 428) {
			imgPath = `${path}-mobile`
		} else {
			imgPath = `${path}-desktop`
		}
	}

	const img = useImageGenerator({
		path: imgPath,
		ext: 'png',
	})

	/** Define clickable props for the button link */
	let linkProps = isFullyClickable
		? {}
		: isExternal
		? { as: 'a', href: href, target: '_blank', textAlign: 'left' }
		: { onClick }
	if (colorMode == 'dark') {
		linkProps = { ...linkProps }
	}

	return (
		<Stack
			minW={['60%', null]}
			w="full"
			// pointerEvents="none"
			direction={['column', 'row']}
			alignItems={['center', 'center']}
			spacing="1rem">
			<Image
				w={responsive ? 'auto' : '80px'}
				h="auto"
				m={responsive ? '-1rem' : 'inherit'}
				maxH={'80px'}
				src={img.src}
				srcSet={img.srcset}
				transform={imgTransform}
			/>
			{content && (
				<Box w="full" pointerEvents="none" {...linkProps} textAlign={['center', 'left']}>
					{content()}
				</Box>
			)}
			{(href || onClick) && (
				<Flex w="auto" justifyContent={['center', 'end']}>
					<Button variant="outline" {...linkProps} {...btnProps}>
						{buttonText ? buttonText : 'Learn More'}
					</Button>
				</Flex>
			)}
		</Stack>
	)
}

export const FeatureItemFullWidth = ({ item, isFullyClickable = false, ...rest }) => {
	return (
		<FeatureBase {...rest} w="full" item={item} isFullyClickable={isFullyClickable}>
			<FeatureItemBase item={item} isFullyClickable={isFullyClickable} />
		</FeatureBase>
	)
}

const FeatureItemForSlider = ({ item, isFullyClickable = false, ...rest }) => {
	return (
		<Flex
			w={['332px', '600px']}
			maxW={['332px', '600px']}
			minH={['244px', 'auto']}
			ml={['1rem', '0']}
			alignSelf="stretch">
			<FeatureBase
				w={['316px', '584px']}
				minH={['inherit', '114px']}
				maxW={['316px', '584px']}
				maxH={['auto', '114px']}
				item={item}
				isFullyClickable={isFullyClickable}
				{...rest}>
				<FeatureItemBase item={item} isFullyClickable={isFullyClickable} />
			</FeatureBase>
		</Flex>
	)
}

export default FeatureItemForSlider
