import React, { useState } from 'react'
import AliceCarousel from 'react-alice-carousel'
import { ArrowBackIosNewRounded, ArrowForwardIosRounded } from '../../atoms/Icon'

import { Box } from '@chakra-ui/react'
import { SliderArrow } from '../../atoms/SliderArrow'
import FeatureItem from '../FeatureItem'
import {
	GalleryItem,
	GalleryItemTiny,
	GalleryItemSmall,
	GalleryItemHalfWidth,
	GalleryItemFullWidth,
} from '../GalleryItem'

const Carousel = ({ collection, infinite = false, ...rest }) => {
	let size = null
	const items = collection.map((el, i) => {
		let item = <></>
		switch (el.variant) {
			case 'thin':
				item = <FeatureItem key={i} item={el} />
				break
			case 'tiny':
				item = <GalleryItemTiny key={i} item={el} />
				size = 'lg'
				break
			case 'small':
				item = <GalleryItemSmall key={i} item={el} />
				size = 'lg'
				break
			case 'full':
				item = <GalleryItemFullWidth key={i} item={el} />
				size = 'lg'
				break
			case 'half':
				item = <GalleryItemHalfWidth key={i} item={el} />
				break
			default:
				item = <GalleryItem key={i} item={el} />
				size = 'lg'
		}
		return item
	})

	// Carousel ref
	let Carousel

	return (
		<Box position="relative" my="1rem" mx="auto" minH="80px" {...rest}>
			<AliceCarousel
				ref={(el) => (Carousel = el)}
				items={items}
				activeIndex={0}
				autoWidth
				infinite={infinite}
				disableDotsControls
				swipeDelta={20}
				mouseTracking={false}
				touchTracking={true}
				touchMoveDefaultEvents={true}
				preventEventOnTouchMove={true}
				renderPrevButton={() => {
					if (collection.length > 2) {
						return (
							<SliderArrow
								size={size}
								direction="left"
								aria-label="Previous"
								icon={<ArrowBackIosNewRounded w="1rem" h="1rem" color="link" />}
								onClick={() => Carousel.slidePrev()}
							/>
						)
					}
				}}
				renderNextButton={() => {
					if (collection.length > 2) {
						return (
							<SliderArrow
								size={size}
								direction="right"
								aria-label="Next"
								icon={<ArrowForwardIosRounded w="1rem" h="1rem" color="link" />}
								onClick={() => Carousel.slideNext()}
							/>
						)
					}
				}}
			/>
		</Box>
	)
}

export default Carousel
