import React from 'react'
import { Stack, Image, Text, Flex, useColorMode, Heading } from '@chakra-ui/react'
import useImageGenerator from '../../../hooks/useImageGenerator'
import { LaunchRounded, ArrowForwardRounded } from '../../atoms/Icon'
import { useEventContext } from '../../../contexts/EventProvider'

export const GalleryBase = ({ ...rest }) => {
	return (
		<Stack
			borderRadius="var(--vidday-radii-xl)"
			overflow="hidden"
			boxShadow={'md'}
			direction="column"
			justify={['start', 'space-between']}
			alignItems="start"
			spacing="1rem"
			bg="white"
			{...rest}
		/>
	)
}

const GalleryItemBase = ({ item }) => {
	const { colorMode } = useColorMode()
	const { event, isOwner } = useEventContext()
	const { path, title, text, logo, hideIcon, onClick, isExternal, href, hrefFallback } = item

	const img = useImageGenerator({
		path: path,
		ext: 'jpg',
	})

	const lg = useImageGenerator({
		path: logo,
		ext: 'jpg',
	})

	/** replace any variables with current event data */
	const eventHref =
		href && event
			? href.replace(/{event}/g, isOwner ? 'event' : 'e').replace(/{uuid}/g, event?.uuid)
			: hrefFallback || href

	/** Define clickable props for the button link */
	let linkProps = href
		? { as: 'a', href: eventHref, target: isExternal ? '_blank' : '_self', rel: 'nofollow' }
		: { onClick }
	if (colorMode == 'dark') {
		linkProps = { ...linkProps, color: 'white', colorScheme: 'whiteAlpha' }
	}

	const height = () => {
		switch (item.variant) {
			case 'small':
				return '56px'
			default:
				return '70px'
		}
	}

	return (
		<>
			<Stack
				{...linkProps}
				className="marketplace-track-link"
				w="full"
				direction="column"
				alignItems="left"
				spacing="0">
				{path && <Image w="full" h="auto" src={img.src} srcSet={img.srcset} />}
				{item?.variant != 'tiny' && (
					<>
						<Flex w="full" h={height()} direction="row" justifyContent="start">
							{logo && (
								<Image
									borderRadius="full"
									boxSize="48px"
									margin="auto 0 auto 1rem"
									src={lg.src}
									srcSet={lg.srcset}
								/>
							)}
							<Flex
								direction="column"
								justifyContent="center"
								p="1rem 0 1rem 1rem"
								w={logo ? 'calc(100% - 106px)' : 'calc(100% - 42px)'}>
								<Heading
									whiteSpace="nowrap"
									overflow="hidden"
									textOverflow="ellipsis"
									size="sm"
									align="left"
									w="full"
									pointerEvents="none">
									{title}
								</Heading>
								{text && (
									<Text
										fontSize="14px"
										whiteSpace="nowrap"
										overflow="hidden"
										textOverflow="ellipsis"
										align="left"
										w="full"
										pointerEvents="none">
										{text}
									</Text>
								)}
							</Flex>

							<Flex
								position="absolute"
								right="1.25rem"
								h={item?.variant == 'small' ? '56px' : '70px'}
								direction="column"
								justifyContent="center">
								{isExternal ? <LaunchRounded color="link" /> : <ArrowForwardRounded color="link" />}
							</Flex>
						</Flex>
					</>
				)}
			</Stack>
		</>
	)
}

export const GalleryItemFullWidth = ({ item }) => {
	return (
		<GalleryBase item={item}>
			<GalleryItemBase item={item} />
		</GalleryBase>
	)
}

export const GalleryItemHalfWidth = ({ item }) => {
	return (
		<Flex w={['full', '512px']} minH={['236px']} mr="1rem" p="0 .5rem .5rem" alignSelf="stretch">
			<GalleryBase minW={['full', '496px']} item={item}>
				<GalleryItemBase item={item} />
			</GalleryBase>
		</Flex>
	)
}

export const GalleryItemSmall = ({ item }) => {
	return (
		<Flex w={['153px']} minH={['186px']} mr="1rem" p="0 .5rem .5rem" alignSelf="stretch">
			<GalleryBase w="full" item={item}>
				<GalleryItemBase item={item} />
			</GalleryBase>
		</Flex>
	)
}

export const GalleryItemTiny = ({ item }) => {
	return (
		<Flex w={['153px']} minH={['153px']} mr="1rem" p="0 .5rem .5rem" alignSelf="stretch">
			<GalleryBase w="full" item={item}>
				<GalleryItemBase item={item} />
			</GalleryBase>
		</Flex>
	)
}

export const GalleryItem = ({ item }) => {
	return (
		<Flex w={['308px']} h={item?.path ? ['242px'] : ['80px']} mr="1rem" p="0 .5rem .5rem" alignSelf="stretch">
			<GalleryBase w="full" item={item}>
				<GalleryItemBase item={item} />
			</GalleryBase>
		</Flex>
	)
}

export const FeatureItem = ({ item }) => {
	return (
		<Flex w={['332px', '600px']} maxW={['332px', '600px']} minH={['274px', 'auto']} mr="1rem" alignSelf="stretch">
			<GalleryBase
				w={['316px', '584px']}
				minH={['inherit', '114px']}
				maxW={['316px', '584px']}
				maxH={['auto', '114px']}
				item={item}>
				<GalleryItemBase item={item} />
			</GalleryBase>
		</Flex>
	)
}
